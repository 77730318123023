export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
export const GET_USER = 'GET_USER';
export const RESTORE_TOKEN = 'RESTORE_TOKEN';
export const SET_LOADING = 'SET_LOADING';
export const USER_ERROR = 'USER_ERROR';

export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';

export const SET_TOKEN = 'SET_TOKEN';
export const CLEAR_TOKEN = 'CLEAR_TOKEN';

export const SET_ERROR = 'USER_ERROR';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
