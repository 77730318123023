import { createContext, useContext, useReducer, useEffect } from 'react';
import { useRouter } from 'next/router';
import axios from 'axios';
import toast from 'react-hot-toast';

import AuthReducer from './AuthReducer';
import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  SET_LOADING,
  USER_LOADED,
  AUTH_ERROR,
  RESTORE_TOKEN,
} from '../types';

const AuthContext = createContext();

const setAuthToken = (token) => {
  if (token) {
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  } else {
    delete axios.defaults.headers.common.Authorization;
  }
};

const initialState = {
  token: null,
  user: null,
  loading: true,
  role: null,
  error: null,
};

const AuthState = ({ children }) => {
  const router = useRouter();

  const [state, dispatch] = useReducer(AuthReducer, initialState);

  const { user, token, role, loading, error } = state;

  useEffect(() => {
    const token = localStorage.getItem('token');

    if (token !== null) {
      setAuthToken(token);
      restoreToken();
      loadUser();
    } else {
      setLoading(false);
      if (router.pathname !== '/register') router.push('/login');
    }
  }, []);

  //setLoading
  const setLoading = (value) => {
    dispatch({
      type: SET_LOADING,
      payload: value,
    });
  };

  //restore token
  const restoreToken = async () => {
    const token = localStorage.getItem('token');
    dispatch({
      type: RESTORE_TOKEN,
      payload: token,
    });
  };

  //load user
  const loadUser = async () => {
    setLoading(true);
    if (state.token) {
      setAuthToken(state.token);
    }

    try {
      const res = await axios.get(
        `${process.env.NEXT_PUBLIC_API}/usersAuth/me`
      );
      dispatch({
        type: USER_LOADED,
        payload: res.data,
      });
    } catch (err) {
      console.log(err);
      localStorage.removeItem('token');
      dispatch({
        type: AUTH_ERROR,
        payload: err.data,
      });
    }
  };

  //login
  const login = async (email, password) => {
    setLoading(true);

    try {
      const res = await axios.post(
        `${process.env.NEXT_PUBLIC_API}/usersAuth/login`,
        { email, password }
      );

      localStorage.setItem('token', res.data.token);
      setAuthToken(res.data.token);
      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data.token,
      });
      loadUser();
    } catch (err) {
      setLoading(false);
      toast.error(err?.response?.data.error ?? 'Server is down', 'error');
    }
  };

  const logout = async () => {
    localStorage.removeItem('token');
    await axios.get(`${process.env.NEXT_PUBLIC_API}/citizensAuth/logout`);
    setAuthToken();
    dispatch({ type: LOGOUT });
    router.push('/login');
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        token,
        role,
        loading,
        error,
        loadUser,
        login,
        logout,
        restoreToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);

export default AuthState;
