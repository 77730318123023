import {
	AUTH_ERROR,
	GET_USER,
	LOGIN_SUCCESS,
	USER_LOADED,
	LOGIN_FAIL,
	LOGOUT,
	SET_LOADING,
	RESTORE_TOKEN,
} from '../types';

const AuthReducer = (state, action) => {
	const { type, payload } = action;

	switch (type) {
		case SET_LOADING:
			return {
				...state,
				loading: payload,
			};
		case RESTORE_TOKEN:
			return {
				...state,
				loading: false,
				token: payload,
			};
		case LOGIN_SUCCESS:
			return {
				...state,
				loading: false,
				token: payload,
			};
		case USER_LOADED:
			return {
				...state,
				loading: false,
				user: payload.data,
			};
		case AUTH_ERROR:
		case LOGIN_FAIL:
		case LOGOUT:
			return {
				...state,
				loading: false,
				token: null,
				user: null,
				error: payload,
			};
		default:
			return state;
	}
};

export default AuthReducer;
