import Head from 'next/head';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Toaster } from 'react-hot-toast';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import '../styles/style.css';
import AuthState from '@/context/auth/AuthState';

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#0478E3', //#111827
      contrastText: '#ffffff',
    },
  },
  typography: {
    fontFamily: '"neue-haas-grotesk-text", "MV Typewriter",sans-serif',
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          borderColor: '#ff0000',
          input: {
            '&::placeholder': {
              color: '#9ca3af',
            },
          },
        },
      },
      defaultProps: {
        size: 'small',
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '12px',
          backgroundColor: 'transparent !important',
          '& .MuiInputAdornment-root': {
            '& .MuiIconButton-root': {
              color: '#0478E3',
            },
          },
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'transparent',
          },
          '& fieldset': {
            outline: 'none',
            borderColor: 'transparent !important',
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          '& .MuiSelect-outlined': {
            padding: '8.5px 12px',
          },
          '& .MuiSelect-icon': {
            right: '10px',
            fontSize: '20px',
            color: '#0478E3',
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          '&.over .MuiDialog-paper': {
            overflowY: 'visible',
          },
          '& .MuiDialog-paper': {
            borderRadius: '10px',
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '& .MuiInput-root': {
            padding: '6px 4px 7px 8px',
          },
          '& .MuiAutocomplete-endAdornment': {
            right: '10px',
            '& .MuiSvgIcon-root': {
              fontSize: '20px',
              color: '#0478E3',
            },
          },
          '&whiteVariant .MuiChip-root': {
            backgroundColor: '#e0f2fe',
            textTransform: 'capitalize',
            color: '#0284c7',
            '& .MuiChip-deleteIcon': {
              color: '#0478E3',
            },
          },
          '& .MuiChip-root': {
            backgroundColor: '#fff',
            textTransform: 'capitalize',
            color: '#000',
            '& .MuiChip-deleteIcon': {
              color: '#0478E3',
            },
          },
        },
      },
    },
  },
});

const clientSideEmotionCache = createCache({ key: 'css', prepend: true });

function MyApp({
  Component,
  emotionCache = clientSideEmotionCache,
  pageProps,
}) {
  return (
    <CacheProvider value={emotionCache}>
      <Head>
        <meta name='viewport' content='initial-scale=1, width=device-width' />
      </Head>
      <ThemeProvider theme={theme}>
        <AuthState>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
            <CssBaseline />
            <Toaster
              position='top-center'
              toastOptions={{
                duration: 5000,
              }}
            />

            <Component {...pageProps} />
          </LocalizationProvider>
        </AuthState>
      </ThemeProvider>
    </CacheProvider>
  );
}

export default MyApp;
